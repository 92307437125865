import { Injectable, NgZone } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ToolsService } from "src/app/services/utils/tools.service";

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  constructor(
    private AppCMS: AppcmsService,
    private tools: ToolsService,
    private zone: NgZone,
  ) {

  }

  extractTagsFromItems(items: any[], options: any = {}) {
    let tagIds: number[] = [], tags: tag[] = [];

    this.zone.runOutsideAngular(() => {
      if (!!items && !!items.length) {
        items.forEach((item: any) => {
          if (item.hasOwnProperty('tags') && !!item.tags && !!item.tags.length) {
            item.tags.forEach((tag: tag) => {
              if (tagIds.indexOf(tag.uid) === -1 && !!tag.uid) {
                tag.checked = !!(!!options.active && !!options.active.uid && (options.active.uid === tag.uid));
                tag.photo = tag.photo || `${(item.photo || item.thumbnail) || ''}`;
                tag.title = this.tools.trim(tag.title, '"');

                tags.push(tag);
                tagIds.push(tag.uid);
              }
            });
          } else
            if (item.hasOwnProperty('attribures') && !!item.attributes && !!item.attributes.length) {
              console.log('extract tags from attributes: item', item);
            }
        });
      }
    });

    console.log('extractTagsFromItems: tagIds', tagIds);
    console.log('extractTagsFromItems: tags', tags);

    return tags;
  }

  getAll(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    return this.AppCMS.loadPluginData('tags', options, [], {}, blForceRefresh);
  }

}